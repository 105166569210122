<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<b-card class="mt-4" no-body>
				<b-card-body>
					<per-assignee />
				</b-card-body>
			</b-card>
		</transition>
	</errorContainer>
</template>

<script>
import PerAssignee from '@/components/PerAssignee/PerAssignee';
export default {
	name: 'index',

	components: {
		PerAssignee
	},

	data() {
		return {
			erreur: {},
			warning: ''
		};
	}
};
</script>

<style scoped></style>
