var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-peer-assignee",
        "hide-footer": "",
        size: _vm.$screen.width <= 992 ? "md" : "lg",
      },
      on: { hidden: _vm.emitCloseEvent },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [_vm._v("\n\t\t" + _vm._s(_vm.selectedEvent.text) + "\n\t")]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("div", { staticClass: "float-right" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.FormMSG(57, "Real start")) +
                        " : " +
                        _vm._s(
                          _vm.formatDisplayDate(_vm.selectedEvent.realStart)
                        )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.FormMSG(58, "Real end")) +
                        " : " +
                        _vm._s(_vm.formatDisplayDate(_vm.selectedEvent.realEnd))
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          JSON.stringify(_vm.selectedEvent) !== "{}"
            ? _c(
                "div",
                [
                  _vm.selectedEvent.clientId !== 0
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: _vm.FormMSG(165, "Client") },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      value:
                                        _vm.selectedEvent.clientId !== 0
                                          ? _vm.selectedEvent.client.name
                                          : "",
                                      disabled: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedEvent.clientId !== 0
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: _vm.FormMSG(166, "Address") },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("b-form-input", {
                                        staticStyle: { width: "95%" },
                                        attrs: {
                                          value: _vm.addressFormatter,
                                          disabled: "",
                                        },
                                      }),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "float-right",
                                          attrs: { variant: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleClickLocalisation(
                                                _vm.selectedEvent
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "icon-location-pin",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c("div", { staticClass: "text-center" }, [_vm._v("No client")]),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    _c(
                      "b-button",
                      { staticClass: "mr-2", on: { click: _vm.hideModal } },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(10, "Close")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-button",
                      { attrs: { variant: "success", type: "submit" } },
                      [_c("span", [_vm._v(_vm._s(_vm.FormMSG(42, "Save")))])]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }