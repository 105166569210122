<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-col sm="12">
			<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
				{{ FormMSG(159, 'Per assignee') }}
			</h1>
		</b-col>
		<b-row class="mb-4">
			<b-col sm="12" md="12" lg="4" xl="4">
				<fieldset class="scheduler-border my-0 py-0 pb-3">
					<legend class="scheduler-border">
						{{ FormMSG(14, 'Menus') }}
					</legend>
					<b-row>
						<b-col :class="`${$screen.lg || $screen.md || $screen.width < 768 ? 'mb-2' : ''}`">
							<display-settings-per-assignee
								@settings-per-assignee:change-cell-width="handleSettingsChangeCellWidth"
								@settings-per-assignee:change-cell-height="handleSettingsChangeCellHeight"
							/>
							<router-link to="/plannings" class="btn btn-warning btn-md">
								<i class="fa fa-calendar" /> {{ FormMSG(39, 'Plannings') }}
							</router-link>
						</b-col>
					</b-row>
				</fieldset>
			</b-col>
			<b-col sm="12" md="12" lg="8" xl="8">
				<fieldset class="scheduler-border my-0 py-0">
					<legend class="scheduler-border">
						{{ FormMSG(15, 'Advanced search') }}
					</legend>
					<filter-planning
						:field-filter="'task'"
						:filter-for-task="true"
						:to-me="false"
						:only-parent="chckOnlyParent"
						init-active-view="month"
						@filters:selected="handleFiltersSelected"
						@new-active-view="handleNewActiveView"
						@today-event="handleTodayEvent"
					/>
				</fieldset>
			</b-col>
		</b-row>
		<b-row id="container-per-assignee">
			<b-col>
				<vue-cal
					ref="calTasks"
					:locale="lang"
					:hide-body="true"
					:hide-weekends="true"
					:selected-date="selectedDate"
					hide-view-selector
					:hide-week-days="[1, 2, 3, 4, 5, 6, 7]"
					:active-view.sync="activeView"
					@view-change="handleViewChange"
				/>
			</b-col>
		</b-row>
		<b-row style="margin-top: -33px">
			<b-col>
				<DayPilotScheduler id="dp" ref="scheduler" :config="config" />
				<modal-peer-assignee :open="showModalPeerAssignee" :selected-event="selectedEvent" @modal:close="handleCloseModalPeerAssignee" />
			</b-col>
		</b-row>
		<b-row class="mt-3">
			<b-col cols="8">
				<p style="font-weight: bolder; font-size: 14px">
					{{ FormMSG(166, 'Bar color information') }}
				</p>
				<div>
					<ul style="list-style: none; font-size: 13px">
						<li
							:class="`status${status.value}`"
							v-for="(status, index) in statuses"
							:key="index"
							v-if="status.value !== 9"
							style="display: inline-block; margin-right: 15px"
						>
							<span
								style="display: inline-block; border-radius: 2px; width: 12px; height: 12px; margin-right: 10px"
								:class="`bg-status${status.value}`"
							></span
							>{{ status.text }}
						</li>
					</ul>
				</div>
			</b-col>
			<b-col cols="4">
				<p style="font-weight: bolder; font-size: 14px">
					{{ FormMSG(167, 'Text color information') }}
				</p>
				<div>
					<ul style="list-style: none; font-size: 13px">
						<li v-for="(firstAssigne, index) in listFirstAssigne" :key="index" style="display: block; margin-bottom: 5px">
							<span
								style="display: inline-block; border-radius: 2px; width: 12px; height: 12px; margin-right: 10px"
								:style="`background-color: ${firstAssigne.color}`"
							/>
							{{ firstAssigne.name + ' ' + firstAssigne.firstName }}
						</li>
					</ul>
				</div>
			</b-col>
		</b-row>
	</errorContainer>
</template>

<script>
import { DayPilot, DayPilotScheduler } from 'daypilot-pro-vue';
import { getUsers } from '@/cruds/users.crud';
import { getTasks } from '@/cruds/task.crud';
import { store } from '@/store';
import moment from 'moment';
import ModalPeerAssignee from './ModalPeerAssignee';
import languageMessages from '@/mixins/languageMessages';
import DisplaySettingsPerAssignee from './DisplaySettingsPerAssignee';
import FilterPlanning from '@/components/Plannings/Filter/FilterPlanning';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/fr.js';
import 'vue-cal/dist/i18n/it.js';
import 'vue-cal/dist/i18n/de.js';
import 'vue-cal/dist/i18n/nl.js';
import 'vue-cal/dist/i18n/pt-br.js';
import 'vue-cal/dist/i18n/sk.js';
import GlobalMixin from '@/mixins/global.mixin';
import randomColorList from './random-color.json';

const colorStatus = ['#d3d3d3', '#ffcc00', '#ff540d', '#2ecd6f', '#ff9900', '#f81c07', '#ff00df', '#a875ff', '#6bc950'];

export default {
	name: 'PerAssignee',

	components: {
		DayPilotScheduler,
		ModalPeerAssignee,
		DisplaySettingsPerAssignee,
		FilterPlanning,
		VueCal
	},

	mixins: [languageMessages, GlobalMixin],

	data: function () {
		return {
			erreur: {},
			warning: '',
			showModalPeerAssignee: false,
			selectedEvent: {},
			startDate: DayPilot.Date.today().firstDayOfMonth(),
			nbDays: DayPilot.Date.today().daysInMonth(),
			tasks: [],
			originalTasks: [],
			cookieName: '',
			cookiesPerAssignee: {
				startDate: new Date(moment().startOf('months').add(1, 'days')).toISOString(),
				endDate: new Date(moment().endOf('months')).toISOString(),
				filterActive: '()',
				isMine: false,
				activeView: 'week',
				selectedDate: new Date(),
				chckOnlyParent: false
			},
			selectedDate: new Date(),
			activeView: 'month',
			filter: {
				startDate: new Date(moment().startOf('months').add(1, 'days')).toISOString(),
				endDate: new Date(moment().endOf('months')).toISOString(),
				isMine: false,
				filterActive: '()'
			},
			cellWidth: 50,
			cellHeight: 30,
			listFirstAssigne: [],
			initActiveView: true,
			initViewChange: true,

			config: {
				cellWidthSpec: 'Fixed',
				timeHeaders: [
					{
						groupBy: 'Month'
					},
					{
						groupBy: 'Day',
						format: 'd'
					}
				],
				scale: 'Day',
				businessBeginsHour: 1,
				timeRangeSelectedHandling: 'Disabled',
				eventMoveHandling: 'Disabled',
				eventResizeHandling: 'Disabled',
				eventDeleteHandling: 'Disabled',
				eventClickHandling: 'Select',
				onEventSelected: (args) => {
					this.handleCellClicked(args.e.data);
				},
				eventHoverHandling: 'Bubble',
				bubble: new DayPilot.Bubble({
					onLoad: (args) => {
						// if event object doesn't specify "bubbleHtml" property
						// this onLoad handler will be called to provide the bubble HTML
						args.html = args.source.data.text;
					}
				}),
				onBeforeEventRender: (args) => {
					args.data.barColor = args.data.color;
				},
				contextMenu: new DayPilot.Menu({
					items: [
						{
							text: 'Delete',
							onClick: (args) => {
								const dp = args.source.calendar;
								dp.events.remove(args.source);
							}
						}
					]
				}),
				rowMinHeight: 50
			}
		};
	},

	computed: {
		// DayPilot.Scheduler object - https://api.daypilot.org/daypilot-scheduler-class/
		scheduler() {
			return this.$refs.scheduler.control;
		},
		chckOnlyParent: {
			get() {
				return false;
			},
			set(val) {
				return val;
			}
		},
		statuses() {
			return this.FormMenu(1122);
		}
	},

	async created() {
		await this.getCookiesPerAssignee();
	},

	async mounted() {
		this.$loading(true);
		// await this.getCookiesPerAssignee()
		await this.getCookiesSettingsPerAssignee();

		this.nbDays = Math.round(this.durationDateBetweenTwoDate(this.filter.startDate, this.filter.endDate));

		const date = new Date(this.filter.startDate);
		const cellWidth = this.cellWidth;
		const cellHeight = this.cellHeight;
		await this.$refs.scheduler.control.update({
			eventHeight: cellHeight,
			cellWidth,
			startDate: DayPilot.Date.fromYearMonthDay(date.getFullYear(), date.getMonth() + 1, date.getDate()),
			days: this.nbDays
		});

		await this.loadUsers();
		await this.loadTasks();

		await this.getTasksWithFilter();
		this.scheduler.update({ events: this.tasks });
		//await this.scheduler.message("Welcome!");

		await this.dropDivDemo();
		this.$loading(false);
	},

	methods: {
		async handleTodayEvent() {
			this.filter.startDate = new Date().format('YYYY-MM-DD') + 'T00:00:00Z';
			this.filter.endDate = new Date().format('YYYY-MM-DD') + 'T23:59:00Z';
			this.nbDays = this.durationDateBetweenTwoDate(this.filter.startDate, this.filter.endDate);

			const date = new Date(this.filter.startDate);
			this.setCookiesPerAssignee('handleTodayEvent');
			await this.loadTasks();
			this.$refs.scheduler.control.update({
				startDate: DayPilot.Date.fromYearMonthDay(date.getFullYear(), date.getMonth() + 1, date.getDate()),
				days: this.nbDays
			});
		},
		handleNewActiveView(payload) {
			this.activeView = payload;
			if (!this.initActiveView && this.cookiesPerAssignee.activeView !== this.activeView) {
				this.setCookiesPerAssignee('handleNewActiveView');
			}
			this.initActiveView = false;
		},
		async handleViewChange(payload) {
			this.filter.startDate = moment(payload.startDate).utc();
			this.filter.endDate = moment(payload.endDate).utc();
			this.nbDays = this.durationDateBetweenTwoDate(this.filter.startDate, this.filter.endDate);

			const date = new Date(this.filter.startDate);
			if (!this.initViewChange && this.cookiesPerAssignee.activeView !== this.activeView) {
				this.setCookiesPerAssignee('handleViewChange');
			}
			this.initViewChange = false;
			await this.loadTasks();
			this.$refs.scheduler.control.update({
				startDate: DayPilot.Date.fromYearMonthDay(date.getFullYear(), date.getMonth() + 1, date.getDate()),
				days: this.nbDays
			});
		},
		async getCookiesPerAssignee() {
			this.cookieName = 'ck_plannings_component_for_plannings';
			if (this.$cookies.isKey(this.cookieName)) {
				this.cookiesPerAssignee = this.$cookies.get(this.cookieName);
				this.filter.filterActive = this.cookiesPerAssignee.filterActive;
				this.filter.startDate = new Date(this.cookiesPerAssignee.startDate).toISOString();
				this.filter.endDate = new Date(this.cookiesPerAssignee.endDate).toISOString();
				this.activeView = this.cookiesPerAssignee.activeView;
				this.chckOnlyParent = this.cookiesPerAssignee.chckOnlyParent;
				this.selectedDate = new Date(this.cookiesPerAssignee.selectedDate);
			} else {
				this.$cookies.set(this.cookieName, this.cookiesPerAssignee, '2d');
			}
		},
		setCookiesPerAssignee(here) {
			this.cookiesPerAssignee.startDate = this.filter.startDate;
			this.cookiesPerAssignee.endDate = this.filter.endDate;
			this.cookiesPerAssignee.filterActive = this.filter.filterActive;
			this.cookiesPerAssignee.activeView = this.activeView;
			this.cookiesPerAssignee.selectedDate = this.filter.startDate;
			this.cookiesPerAssignee.chckOnlyParent = this.chckOnlyParent;
			this.$cookies.set(this.cookieName, this.cookiesPerAssignee, '2d');
		},
		handleFiltersSelected({ payload, condition }) {
			this.filter.filterActive = condition;
			this.getTasksWithFilter();
			this.scheduler.update({ events: this.tasks });
			this.setCookiesPerAssignee('handleFiltersSelected');
		},
		async getTasksWithFilter() {
			if (this.filter.filterActive !== '()') {
				this.tasks = [];
				for (let i = 0; i < this.originalTasks.length; i++) {
					const task = this.originalTasks[i];
					if (eval(this.filter.filterActive)) {
						this.tasks.push(task);
					}
				}
			} else {
				this.tasks = this.originalTasks;
			}
		},
		getCookiesSettingsPerAssignee() {
			if (this.$cookies.isKey('ckk_display_settings_per_assignee')) {
				const cookies = this.$cookies.get('ckk_display_settings_per_assignee');

				this.cellWidth = cookies.cellWidth;
				this.cellHeight = cookies.cellHeight;
			}
		},
		async handleSettingsChangeCellWidth(payload) {
			this.$refs.scheduler.control.update({
				cellWidth: payload.cellWidth
			});
			await this.dropDivDemo();
		},
		async handleSettingsChangeCellHeight(payload) {
			this.$refs.scheduler.control.update({
				eventHeight: payload.cellHeight
			});
			await this.dropDivDemo();
		},
		handleCloseModalPeerAssignee() {
			this.showModalPeerAssignee = false;
			this.selectedEvent = {};
		},
		handleCellClicked(data) {
			this.selectedEvent = data;
			this.showModalPeerAssignee = true;
		},
		async loadTasks() {
			// placeholder for an AJAX call
			const tasks = await getTasks(-1, {
				startDate: this.filter.startDate,
				endDate: this.filter.endDate,
				orderBy: true,
				loadSubTasks: false,
				loadItems: false,
				loadTeams: true,
				loadUsers: true,
				loadTreeView: false,
				loadDepartment: this.$route.name === 'AdministrationPlannings' || this.$route.name === 'myPlannings' ? store.state.myProfile.department : 0
			});
			let data = [];
			this.listFirstAssigne = [];
			for (let i = 0; i < tasks.length; i++) {
				const task = tasks[i];
				let firstAssigne = null;
				const colorAndTextStatus = await this.getColorAndTextStatus(task.status);
				if (task.assignedTo.length > 0) {
					for (let j = 0; j < task.assignedTo.length; j++) {
						const user = task.assignedTo[j];
						// GET FIRST ASSIGNEE
						if (j === 0) {
							firstAssigne = user;
							const findIndexFirstAssigne = this.listFirstAssigne.findIndex((lfa) => parseInt(lfa.id) === parseInt(firstAssigne.id));
							if (findIndexFirstAssigne === -1) {
								this.listFirstAssigne.push(firstAssigne);
							}
						}

						const desiredStart = new Date(task.desiredStart);
						data.push({
							id: `${task.taskPlanningId}_user${j + 1}`,
							resource: parseInt(user.id),
							start: new Date(Date.UTC(desiredStart.getFullYear(), desiredStart.getMonth(), desiredStart.getDate(), 0, 0, 0)).toISOString(),
							end: new Date(Date.UTC(desiredStart.getFullYear(), desiredStart.getMonth(), desiredStart.getDate(), 23, 59, 59)).toISOString(),
							text: task.name,
							color: `#${colorAndTextStatus.backgroundColor}`,
							fontColor: `${firstAssigne.color}`,
							clientId: task.clientId,
							taskType: task.type,
							priority: task.priority,
							status: task.status,
							client: task.client,
							description: task.description,
							taskPlanningId: task.taskPlanningId,
							taskParentRootName: task.taskParentRootName,
							locationId: task.locationId,
							location: task.location,
							realStart: task.start,
							realEnd: task.end,
							dayDuration: task.dayDuration,
							timeDuration: task.duration,
							desiredStart: task.desiredStart,
							originalDuration: task.duration,
							dueDate: task.dueDate,
							assignedTo: [
								{
									id: parseInt(user.id),
									name: user.name
								}
							],
							assignedTeam: []
						});
					}
				}
				if (task.assignedTeam.length > 0) {
					for (let j = 0; j < task.assignedTeam.length; j++) {
						const team = task.assignedTeam[j];
						if (team.members.length > 0) {
							for (let k = 0; k < team.members.length; k++) {
								const member = team.members[k];
								const desiredStart = new Date(task.desiredStart);
								// alert(moment(desiredStart).format('YYYY-MM-DDThh:mm:ssZ'))
								const tmp = {
									id: `${task.taskPlanningId}_team${j + 1}${k}`,
									resource: parseInt(member.id),
									start: new Date(
										Date.UTC(desiredStart.getFullYear(), desiredStart.getMonth(), desiredStart.getDate(), 0, 0, 0)
									).toISOString(),
									end: new Date(
										Date.UTC(desiredStart.getFullYear(), desiredStart.getMonth(), desiredStart.getDate(), 23, 59, 59)
									).toISOString(),
									text: task.name,
									color: `#${colorAndTextStatus.backgroundColor}`,
									fontColor: `rgb(${firstAssigne === null ? '#000000' : firstAssigne.color})`,
									clientId: task.clientId,
									taskType: task.type,
									priority: task.priority,
									status: task.status,
									client: task.client,
									description: task.description,
									taskPlanningId: task.taskPlanningId,
									taskParentRootName: task.taskParentRootName,
									locationId: task.locationId,
									location: task.location,
									realStart: task.start,
									realEnd: task.end,
									dayDuration: task.dayDuration,
									timeDuration: task.duration,
									desiredStart: task.desiredStart,
									originalDuration: task.duration,
									dueDate: task.dueDate,
									assignedTo: [
										{
											id: parseInt(member.id),
											name: member.name
										}
									],
									assignedTeam: [team]
								};
								const findIndex = data.findIndex((d) => d.resource === tmp.resource && d.start === tmp.start && d.text === tmp.text);
								if (findIndex === -1) {
									data.push(tmp);
								} else {
									data[findIndex].assignedTeam = [team];
								}
							}
						}
					}
				}
			}
			this.tasks = data;
			this.originalTasks = data;
			this.scheduler.update({ events: data });
		},
		async loadUsers() {
			// placeholder for an AJAX call
			const users = await getUsers({ myProject: true });
			let data = [];
			for (let i = 0; i < users.length; i++) {
				const user = users[i];
				data.push({
					name: user.fullName,
					id: parseInt(user.id),
					expanded: false
				});
			}
			this.scheduler.update({ resources: data });
		},
		updateColor(e, color) {
			var dp = this.scheduler;
			e.data.color = color;
			dp.events.update(e);
			dp.message('Color updated');
		},
		async dropDivDemo() {
			await this.$nextTick(() => {
				const schedulerDefaultCorner = document.querySelectorAll('.scheduler_default_corner div');
				schedulerDefaultCorner[1].style.display = 'none';
			});
		},
		durationDateBetweenTwoDate(start, end) {
			let date1 = new Date(start).format('YYYY-MM-DD') + 'T00:00:00Z';
			let date2 = new Date(end).format('YYYY-MM-DD') + 'T00:00:00Z';
			date1 = new Date(date1);
			date2 = new Date(date2);

			// To calculate the time difference of two dates
			let Difference_In_Time = date2.getTime() - date1.getTime();

			// To calculate the no. of days between two dates
			return Difference_In_Time / (1000 * 3600 * 24) + 1;
		},
		async getColorAndTextStatus(statusId) {
			const findIndex = this.statuses.findIndex((cS) => parseInt(cS.value) === parseInt(statusId));

			let backgroundColor = '';
			if (findIndex === -1) {
				backgroundColor = '#000000';
			} else {
				backgroundColor = await this.base16ToHexa(this.statuses[findIndex].factor);
			}

			const textColor = this.invertColor(backgroundColor);

			return {
				backgroundColor,
				textColor
			};
		}
	}
};
</script>

<style lang="scss">
.vuecal__menu,
.vuecal__cell-events-count {
	background-color: #008b8b;
}
.vuecal__title-bar {
	background-color: #e4f5ef;
}
.vuecal__cell--today,
.vuecal__cell--current {
	background-color: rgba(240, 240, 255, 0.4);
}
.vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
	background-color: rgba(235, 255, 245, 0.4);
}
.vuecal__cell--selected:before {
	border-color: rgba(66, 185, 131, 0.5);
}
/* Cells and buttons get highlighted when an event is dragged over it. */
.vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
.vuecal__cell-split--highlighted {
	background-color: rgba(195, 255, 225, 0.5);
}
.vuecal__arrow.vuecal__arrow--highlighted,
.vuecal__view-btn.vuecal__view-btn--highlighted {
	background-color: rgba(136, 236, 191, 0.25);
}

.vuecal--month-view .vuecal__cell {
	height: 80px;
}

.vuecal--month-view .vuecal__cell-content {
	justify-content: flex-start;
	height: 100%;
	align-items: flex-end;
}

.vuecal--month-view .vuecal__cell-date {
	padding: 4px;
}
.vuecal--month-view .vuecal__no-event {
	display: none;
}
.vuecal__event--dragging {
	background-color: rgba(60, 60, 60, 0.3);
}
.external-event {
	margin-bottom: 0.5em;
	width: 100%;
}
.external-event,
.external-events-drag-and-drop .vuecal__event {
	background-color: rgba(160, 220, 255, 0.5);
	border: 1px solid rgba(0, 100, 150, 0.15);
	padding: 0.2em 0.4em;
	cursor: grab;
}
.vuecal__time-cell-line.hours:before {
	border-color: #42b983;
}
.bc-head-txt-label {
	display: block;
	text-align: left;
	transform: rotate(-90deg);
	margin-top: 19px;
	font-weight: bolder;
	color: white;
	letter-spacing: 2px;
}
.container-hide-show-tasks {
	position: absolute;
	left: 0;
	top: 47%;
	background-color: lightgray;
	height: 60px;
	border-radius: 5px 0 0 5px;
}
.app-body {
	overflow-x: unset;
}

.header-fixed .app-header {
	z-index: 1021;
}

.sidenav-sticky {
	top: 68px;
}

.btn-link.collapse-btn {
	color: #323635;
	text-align: left;
	padding-left: 0;
	padding-right: 0;
	clear: both;
	transition: all 0.4s;
}

.btn-link.collapse-btn:hover,
.btn-link.collapse-btn:active,
.btn-link.collapse-btn:focus {
	padding-left: 8px;
	background-color: #f3f4f5;
	text-decoration: none;
}

.btn-link.collapse-btn i {
	float: right;
	margin: 6px 12px 0 0;
	font-size: 0.7rem;
}
.scroll-area {
	position: relative;
	margin: auto;
	width: 400px;
	height: 300px;
}
/* FOR HIDE THE WEEK DAYS VUE CAL */
#container-per-assignee .vuecal__weekdays-headings,
.vuecal__all-day {
	display: none !important;
}

.completed-label {
	border: 1px dotted rgba(0, 0, 0, 0.15);
	line-height: 22px;
	display: inline-table;
}

.completed-edit {
	color: #29ad5e;
	border: 1px dotted rgba(0, 0, 0, 0.15);
	line-height: 22px;
	font-size: 0.925rem;
}

.completed-edit:hover {
	color: #2ecd6f;
}

.completed-col {
	width: 130px;
}

.map {
	font-size: 1.15rem;
	color: #c44611;
	&:hover {
		color: #ee5514;
	}
}

#dropdown-offer-action .dropdown-menu {
	font-size: 12px !important;
}
#container-per-assignee .vuecal__header {
	z-index: 2;
}
</style>
