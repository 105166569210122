var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "b-button",
        {
          staticClass: "mr-2",
          attrs: { variant: "primary" },
          on: {
            click: function ($event) {
              return _vm.$bvModal.show("settings-per-assignee")
            },
          },
        },
        [
          _c("i", {
            staticClass: "fa fa-cogs",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v("\n\t\t" + _vm._s(_vm.FormMSG(1, "Settings")) + "\n\t"),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "header-class": "header-class-modal-doc-package",
            id: "settings-per-assignee",
            "hide-footer": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _c("i", {
                    staticClass: "fa fa-cogs",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(
                    "\n\t\t\t" + _vm._s(_vm.FormMSG(1, "Settings")) + "\n\t\t"
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(45, "Cell width") } },
                    [
                      _c("b-form-spinbutton", {
                        attrs: { min: "20", max: "200", step: "10" },
                        on: { change: _vm.handleChangeCellWidth },
                        model: {
                          value: _vm.dataToSend.cellWidth,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "cellWidth", $$v)
                          },
                          expression: "dataToSend.cellWidth",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(46, "Cell height") } },
                    [
                      _c("b-form-spinbutton", {
                        attrs: { min: "20", max: "200", step: "10" },
                        on: { change: _vm.handleChangeCellHeight },
                        model: {
                          value: _vm.dataToSend.cellHeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "cellHeight", $$v)
                          },
                          expression: "dataToSend.cellHeight",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-4" },
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-2",
                        attrs: { variant: "primary", disabled: _vm.formBusy },
                        on: { click: _vm.close },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(7, "Close")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "success", disabled: _vm.formBusy },
                        on: { click: _vm.save },
                      },
                      [
                        !_vm.formBusy
                          ? _c("span", [_vm._v(_vm._s(_vm.FormMSG(8, "Save")))])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.FormMSG(9, "In progress"))),
                            ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }