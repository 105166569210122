<template>
	<b-modal id="modal-peer-assignee" v-model="isOpen" hide-footer :size="$screen.width <= 992 ? 'md' : 'lg'" @hidden="emitCloseEvent">
		<template #modal-title>
			{{ selectedEvent.text }}
		</template>
		<b-form @submit.prevent="handleSubmit">
			<b-row>
				<b-col>
					<div class="float-right">
						<p>{{ FormMSG(57, 'Real start') }} : {{ formatDisplayDate(selectedEvent.realStart) }}</p>
						<p>{{ FormMSG(58, 'Real end') }} : {{ formatDisplayDate(selectedEvent.realEnd) }}</p>
					</div>
				</b-col>
			</b-row>
			<div v-if="JSON.stringify(selectedEvent) !== '{}'">
				<b-row v-if="selectedEvent.clientId !== 0">
					<b-col>
						<b-form-group :label="FormMSG(165, 'Client')">
							<b-form-input :value="selectedEvent.clientId !== 0 ? selectedEvent.client.name : ''" disabled />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row v-if="selectedEvent.clientId !== 0">
					<b-col>
						<b-form-group :label="FormMSG(166, 'Address')">
							<div style="display: flex">
								<b-form-input :value="addressFormatter" disabled style="width: 95%" />
								<b-button variant="primary" class="float-right" @click="handleClickLocalisation(selectedEvent)">
									<i class="icon-location-pin" />
								</b-button>
							</div>
						</b-form-group>
					</b-col>
				</b-row>
			</div>
			<div v-else class="text-center">No client</div>
			<b-row class="mt-3">
				<b-col>
					<div class="float-right">
						<b-button class="mr-2" @click="hideModal">
							{{ FormMSG(10, 'Close') }}
						</b-button>
						<b-button variant="success" type="submit">
							<span>{{ FormMSG(42, 'Save') }}</span>
						</b-button>
					</div>
				</b-col>
			</b-row>
		</b-form>
	</b-modal>
</template>

<script>
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import { addressFormatter } from '@/shared/fomaters';
import moment from 'moment';

export default {
	name: 'ModalPeerAssignee',

	mixins: [globalMixin, languageMessages],

	props: {
		open: { type: Boolean, default: false },
		selectedEvent: { type: Object, default: () => ({}) }
	},

	computed: {
		addressFormatter() {
			if (this.selectedEvent.locationId === 0) {
				return addressFormatter(this.selectedEvent.client.address);
			}

			return addressFormatter(this.selectedEvent.location.setAddress);
		},
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	methods: {
		formatDisplayDate(date) {
			if (JSON.stringify(this.selectedEvent) !== '{}') {
				const tmpDate = new Date(date);
				//console.log("FORMAT DISPLAY DATE", new Date(date).format('DD/MM'))
				if (date === '0001-01-01T00:00:00Z') {
					return this.FormMSG(67, 'Not defined');
				}

				return `${moment(tmpDate).format('DD/MM')} ${this.FormMSG(60, 'Hours')} : ${moment(tmpDate).format('HH:mm')}`;
			}
		},
		async handleClickLocalisation(event) {
			if (event.locationId === 0) {
				await this.displayMap(event.client.address);
			} else {
				await this.displayMap(event.location.setAddress);
			}
			this.hideModal();
		},
		async handleSubmit() {
			this.confirmModal(this.sendData, this.FormMSG(66, 'Are you sure ?'));
		},

		sendData() {
			alert('Je suis dans le send data');
		},
		hideModal() {
			this.emitCloseEvent();
			this.$bvModal.hide('modal-peer-assignee');
		},

		emitCloseEvent() {
			this.$emit('modal:close');
		}
	}
};
</script>

<style scoped></style>
