<template>
	<span>
		<b-button variant="primary" @click="$bvModal.show('settings-per-assignee')" class="mr-2">
			<i class="fa fa-cogs" aria-hidden="true" />
			{{ FormMSG(1, 'Settings') }}
		</b-button>

		<b-modal header-class="header-class-modal-doc-package" id="settings-per-assignee" hide-footer>
			<template #modal-title>
				<i class="fa fa-cogs" aria-hidden="true" />
				{{ FormMSG(1, 'Settings') }}
			</template>
			<b-row>
				<b-col>
					<b-form-group :label="FormMSG(45, 'Cell width')">
						<b-form-spinbutton v-model="dataToSend.cellWidth" min="20" max="200" step="10" @change="handleChangeCellWidth" />
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group :label="FormMSG(46, 'Cell height')">
						<b-form-spinbutton v-model="dataToSend.cellHeight" min="20" max="200" step="10" @change="handleChangeCellHeight" />
					</b-form-group>
				</b-col>
			</b-row>
			<b-row class="mt-4">
				<b-col>
					<div class="float-right">
						<b-button variant="primary" class="mr-2" :disabled="formBusy" @click="close">
							{{ FormMSG(7, 'Close') }}
						</b-button>
						<b-button variant="success" :disabled="formBusy" @click="save">
							<span v-if="!formBusy">{{ FormMSG(8, 'Save') }}</span>
							<span v-else>{{ FormMSG(9, 'In progress') }}</span>
						</b-button>
					</div>
				</b-col>
			</b-row>
		</b-modal>
	</span>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import moment from 'moment';
import { DayPilot } from 'daypilot-pro-vue';

export default {
	name: 'DisplaySettingsPerAssignee',

	mixins: [languageMessages],

	data() {
		return {
			formBusy: false,
			dataToSend: {
				cellWidth: 50,
				cellHeight: 30
			},
			cookiesToSend: {
				cellWidth: 50,
				cellHeight: 30
			}
		};
	},

	async mounted() {
		await this.getCookiesSettingsPerAssignee();
	},

	methods: {
		getCookiesSettingsPerAssignee() {
			if (this.$cookies.isKey('ckk_display_settings_per_assignee')) {
				this.cookiesToSend = this.$cookies.get('ckk_display_settings_per_assignee');
				this.dataToSend.cellWidth = this.cookiesToSend.cellWidth;
				this.dataToSend.cellHeight = this.cookiesToSend.cellHeight;
			} else {
				this.$cookies.set('ckk_display_settings_per_assignee', this.cookiesToSend, '2d');
			}
		},
		setCookiesSettignsPerAssignee() {
			this.$cookies.set('ckk_display_settings_per_assignee', this.cookiesToSend);
		},
		handleChangeCellWidth() {
			this.emitEventChangeCellWidth();
		},
		handleChangeCellHeight() {
			this.emitEventChangeCellHeight();
		},
		save() {
			this.cookiesToSend.cellWidth = this.dataToSend.cellWidth;
			this.cookiesToSend.cellHeight = this.dataToSend.cellHeight;

			this.setCookiesSettignsPerAssignee();
			this.$bvModal.hide('settings-per-assignee');
		},
		close() {
			this.getCookiesSettingsPerAssignee();
			this.emitEventChangeCellWidth();
			this.emitEventChangeCellHeight();
			this.$bvModal.hide('settings-per-assignee');
		},
		emitEventChangeCellWidth() {
			this.$emit('settings-per-assignee:change-cell-width', {
				cellWidth: this.dataToSend.cellWidth
			});
		},
		emitEventChangeCellHeight() {
			this.$emit('settings-per-assignee:change-cell-height', {
				cellHeight: this.dataToSend.cellHeight
			});
		}
	}
};
</script>

<style scoped></style>
