var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("b-col", { attrs: { sm: "12" } }, [
        _c(
          "h1",
          {
            class: [
              `${!_vm.$screen.sm ? "main-page-title" : ""}`,
              { "is-pwa": _vm.$isPwa() },
            ],
          },
          [
            _vm._v(
              "\n\t\t\t" + _vm._s(_vm.FormMSG(159, "Per assignee")) + "\n\t\t"
            ),
          ]
        ),
      ]),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } }, [
            _c(
              "fieldset",
              { staticClass: "scheduler-border my-0 py-0 pb-3" },
              [
                _c("legend", { staticClass: "scheduler-border" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.FormMSG(14, "Menus")) +
                      "\n\t\t\t\t"
                  ),
                ]),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        class: `${
                          _vm.$screen.lg ||
                          _vm.$screen.md ||
                          _vm.$screen.width < 768
                            ? "mb-2"
                            : ""
                        }`,
                      },
                      [
                        _c("display-settings-per-assignee", {
                          on: {
                            "settings-per-assignee:change-cell-width":
                              _vm.handleSettingsChangeCellWidth,
                            "settings-per-assignee:change-cell-height":
                              _vm.handleSettingsChangeCellHeight,
                          },
                        }),
                        _c(
                          "router-link",
                          {
                            staticClass: "btn btn-warning btn-md",
                            attrs: { to: "/plannings" },
                          },
                          [
                            _c("i", { staticClass: "fa fa-calendar" }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.FormMSG(39, "Plannings")) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("b-col", { attrs: { sm: "12", md: "12", lg: "8", xl: "8" } }, [
            _c(
              "fieldset",
              { staticClass: "scheduler-border my-0 py-0" },
              [
                _c("legend", { staticClass: "scheduler-border" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.FormMSG(15, "Advanced search")) +
                      "\n\t\t\t\t"
                  ),
                ]),
                _c("filter-planning", {
                  attrs: {
                    "field-filter": "task",
                    "filter-for-task": true,
                    "to-me": false,
                    "only-parent": _vm.chckOnlyParent,
                    "init-active-view": "month",
                  },
                  on: {
                    "filters:selected": _vm.handleFiltersSelected,
                    "new-active-view": _vm.handleNewActiveView,
                    "today-event": _vm.handleTodayEvent,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { attrs: { id: "container-per-assignee" } },
        [
          _c(
            "b-col",
            [
              _c("vue-cal", {
                ref: "calTasks",
                attrs: {
                  locale: _vm.lang,
                  "hide-body": true,
                  "hide-weekends": true,
                  "selected-date": _vm.selectedDate,
                  "hide-view-selector": "",
                  "hide-week-days": [1, 2, 3, 4, 5, 6, 7],
                  "active-view": _vm.activeView,
                },
                on: {
                  "update:activeView": function ($event) {
                    _vm.activeView = $event
                  },
                  "update:active-view": function ($event) {
                    _vm.activeView = $event
                  },
                  "view-change": _vm.handleViewChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticStyle: { "margin-top": "-33px" } },
        [
          _c(
            "b-col",
            [
              _c("DayPilotScheduler", {
                ref: "scheduler",
                attrs: { id: "dp", config: _vm.config },
              }),
              _c("modal-peer-assignee", {
                attrs: {
                  open: _vm.showModalPeerAssignee,
                  "selected-event": _vm.selectedEvent,
                },
                on: { "modal:close": _vm.handleCloseModalPeerAssignee },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c("b-col", { attrs: { cols: "8" } }, [
            _c(
              "p",
              { staticStyle: { "font-weight": "bolder", "font-size": "14px" } },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(166, "Bar color information")) +
                    "\n\t\t\t"
                ),
              ]
            ),
            _c("div", [
              _c(
                "ul",
                { staticStyle: { "list-style": "none", "font-size": "13px" } },
                _vm._l(_vm.statuses, function (status, index) {
                  return status.value !== 9
                    ? _c(
                        "li",
                        {
                          key: index,
                          class: `status${status.value}`,
                          staticStyle: {
                            display: "inline-block",
                            "margin-right": "15px",
                          },
                        },
                        [
                          _c("span", {
                            class: `bg-status${status.value}`,
                            staticStyle: {
                              display: "inline-block",
                              "border-radius": "2px",
                              width: "12px",
                              height: "12px",
                              "margin-right": "10px",
                            },
                          }),
                          _vm._v(_vm._s(status.text) + "\n\t\t\t\t\t"),
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
            ]),
          ]),
          _c("b-col", { attrs: { cols: "4" } }, [
            _c(
              "p",
              { staticStyle: { "font-weight": "bolder", "font-size": "14px" } },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(167, "Text color information")) +
                    "\n\t\t\t"
                ),
              ]
            ),
            _c("div", [
              _c(
                "ul",
                { staticStyle: { "list-style": "none", "font-size": "13px" } },
                _vm._l(_vm.listFirstAssigne, function (firstAssigne, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticStyle: { display: "block", "margin-bottom": "5px" },
                    },
                    [
                      _c("span", {
                        staticStyle: {
                          display: "inline-block",
                          "border-radius": "2px",
                          width: "12px",
                          height: "12px",
                          "margin-right": "10px",
                        },
                        style: `background-color: ${firstAssigne.color}`,
                      }),
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(
                            firstAssigne.name + " " + firstAssigne.firstName
                          ) +
                          "\n\t\t\t\t\t"
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }